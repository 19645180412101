import React, {FC} from 'react';
import styled from 'styled-components';

import {ArrowNavigator} from '../../../../shared/components/arrowNavigator';
import {StepperNavigator} from '../../../../shared/components/stepperNavigator';

type Props = {
  activeStep: number;
  navigation?: boolean;
  title: string;
};

export const OrderRecordsWrapper: FC<Props> = ({
  title,
  activeStep,
  navigation = true,
  children,
}) => (
  <Container>
    {navigation && <ArrowNavigator title={title} />}
    <Content>
      {navigation && <StepperNavigator activeStep={activeStep} />}
      <FormContainer navigation={navigation}>{children}</FormContainer>
    </Content>
  </Container>
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const FormContainer = styled.div<{navigation?: boolean}>`
  width: 464px;
  display: flex;
  flex-direction: column;
  margin-top: ${({navigation}) => (navigation ? '24px' : '0')};
  margin-left: ${({navigation}) => (navigation ? '40px' : '0')};
`;
