import React, {FC, Fragment} from 'react';
import {useHistory} from 'react-router-dom';
import {Divider as MuiDivider} from '@material-ui/core';
import styled from 'styled-components';

import {envConfig} from '../../../../env';
import {AddButton} from '../../../shared/components/button';
import {
  List,
  ListItemKey,
  ListItemValue,
} from '../../../shared/components/list';
import {UploadRecordsIcon} from '../../../shared/icons/uploadRecordsIcon';
import {getFullName} from '../../../shared/utils/getFullName';
import {PORTAL_PATIENTS_ROUTES} from '../constants/routes';
import {PatientsProfileDetailsFormattedType} from '../constants/types';

import {PatientEhrsList} from './patientEhrList';
import {PatientPageHeaderTitle} from './patientPageHeaderTitle';
import {ProfileAvatar} from './profileAvatar';

type Props = {
  patientsProfileDetails: PatientsProfileDetailsFormattedType;
};

export const PatientPageHeader: FC<Props> = React.memo(
  ({patientsProfileDetails}) => {
    const {publicDetails, personalDetails, addressDetails, mrnDetails} =
      patientsProfileDetails;
    const history = useHistory();

    return (
      <ComponentWrapper>
        <div>
          <ProfileAvatar
            firstName={publicDetails.firstName}
            lastName={publicDetails.lastName}
            src={publicDetails.src}
          />
        </div>
        <HeaderBodyWrapper>
          <PatientPageHeaderTitle
            status={patientsProfileDetails?.connection.status}
            title={getFullName(
              publicDetails.firstName,
              publicDetails.lastName,
              publicDetails.middleName
            )}
          />
          <DataWrapper>
            <PatientDetailsWrapper className="PatientDetailsWrapper">
              <StyledList width="fit-content">
                {personalDetails.map(({title, value}) => (
                  <Fragment key={title}>
                    <ListItemKey key={title}>{title}</ListItemKey>
                    <ListItemValue key={value}>{value}</ListItemValue>
                  </Fragment>
                ))}
              </StyledList>
              <StyledList width="fit-content">
                {addressDetails.map(({title, value}) => (
                  <Fragment key={title}>
                    <ListItemKey key={title}>{title}</ListItemKey>
                    <ListItemValue key={value}>{value}</ListItemValue>
                  </Fragment>
                ))}
              </StyledList>
            </PatientDetailsWrapper>

            <StyledDivider orientation="vertical" flexItem />

            <PatientEHRWrapper className="PatientEHRWrapper">
              <PatientEhrsList
                consumerUuid={
                  patientsProfileDetails?.publicDetails.consumerUuid
                }
                items={mrnDetails}
                patientName={getFullName(
                  publicDetails.firstName,
                  publicDetails.lastName,
                  publicDetails.middleName
                )}
              />
            </PatientEHRWrapper>
            {envConfig.REACT_APP_ORDER_RECORDS_TAB_ENABLED && (
              <>
                <StyledDivider orientation="vertical" flexItem />
                <StyledAddButton
                  text="Upload Records"
                  onClick={() =>
                    history.push(
                      PORTAL_PATIENTS_ROUTES.UPLOAD_MEDICAL_RECORDS_DATA.replace(
                        ':patientUuid',
                        patientsProfileDetails?.publicDetails.consumerUuid
                      )
                    )
                  }
                  testID="uploadRecordsBtn"
                  icon={<UploadRecordsIcon style={{width: 24, height: 24}} />}
                />
              </>
            )}
          </DataWrapper>
        </HeaderBodyWrapper>
      </ComponentWrapper>
    );
  }
);

PatientPageHeader.displayName = 'PatientPageHeader';

const ComponentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  width: 90%;
`;

const DataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 40px;

  @media (max-width: 1260px) {
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
  }
`;

const PatientEHRWrapper = styled.div`
  &.PatientEHRWrapper {
    display: grid;
    min-width: fit-content;
  }
`;

const PatientDetailsWrapper = styled.div`
  &.PatientDetailsWrapper {
    flex-grow: 1;
    display: grid;
    grid-template-columns: fit-content(100%) fit-content(100%);
    column-gap: 56px;
    width: fit-content;
    justify-content: start;
    align-items: start;
    max-width: fit-content;

    @media (max-width: 1570px) {
      grid-template-columns: auto;
      grid-row-gap: 10px;

      dt {
        width: 86px;
      }
    }
  }
`;

const HeaderBodyWrapper = styled.div`
  width: 100%;
`;

const StyledDivider = styled(MuiDivider)`
  @media (max-width: 1260px) {
    height: 1px;
    width: 100%;
  }
`;

const StyledAddButton = styled(AddButton)`
  width: 164px;
  min-width: 164px !important;
  white-space: nowrap;
  padding-left: 12px;
`;

const StyledList = styled(List)`
  white-space: nowrap;
  text-overflow: ellipsis;
`;
