import React, {FC, useState} from 'react';

import {DeliveryMethodForm} from '../components/deliveryMethodForm';
import {OrderRecordsOptions} from '../components/orderRecords/orderRecordsOptions';
import {OrderRecordsWrapper} from '../components/orderRecords/orderRecordsWrapper';
import {OrderRequestSummary} from '../components/orderRecords/orderRequestSummary';

interface OrderRecordsPageProps {
  navigation?: boolean;
  onBack?: () => void;
}

export const OrderRecordsPage: FC<OrderRecordsPageProps> = ({
  navigation = true,
  onBack,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedRequestType, setSelectedRequestType] = useState<string | null>(
    'TEXT'
  );
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<
    string | null
  >('standard');
  const [deliveryNotes, setDeliveryNotes] = useState<{
    text: string;
    image: string;
  }>({
    text: '',
    image: '',
  });

  const goNext = () => setActiveStep(prev => prev + 1);
  const goBack = () => setActiveStep(prev => (prev > 0 ? prev - 1 : 0));

  let formContent: React.ReactNode = null;
  switch (activeStep) {
    case 0:
      formContent = (
        <OrderRecordsOptions
          title="Order Records"
          description="Please select the type of records you want to order from this provider."
          selectedIndex={selectedRequestType}
          onSelectIndex={setSelectedRequestType}
          onNext={goNext}
          hasSkipButton={!navigation}
        />
      );
      break;
    case 1:
      formContent = (
        <DeliveryMethodForm
          title="Select Delivery Method"
          description="Please select delivery method"
          onNext={goNext}
          onBack={goBack}
          selectedRequestType={selectedRequestType}
          selectedDeliveryMethod={selectedDeliveryMethod}
          onSelectDeliveryMethod={setSelectedDeliveryMethod}
          deliveryNotes={deliveryNotes}
          setDeliveryNotes={setDeliveryNotes}
          isSmall={!navigation}
        />
      );
      break;
    case 2:
      formContent = (
        <OrderRequestSummary
          selectedRequestType={selectedRequestType}
          selectedDeliveryMethod={selectedDeliveryMethod}
          onNext={goNext}
        />
      );
      break;
    default:
      formContent = null;
  }

  return (
    <OrderRecordsWrapper
      title="Order Records"
      navigation={navigation}
      activeStep={activeStep}
    >
      {formContent}
    </OrderRecordsWrapper>
  );
};
