import React, {useState} from 'react';
import styled from 'styled-components';

import {StepSidebar} from '../../../shared/components/stepBar';
import {STEP} from '../constants/stepper';
import {INITIAL_USER_PORTAL} from '../constants/userPortal';

import {ConfirmUser} from './confirmUser';
import {InviteUser} from './inviteUser';

const invitationSteps = ['Invite Admin Portal User', 'Confirm Invitation'];

export const UserInvitationPageIssuer = () => {
  const [activeStep, setActiveStep] = useState(STEP.FIRST);
  const [informationState, setInformationState] = useState(INITIAL_USER_PORTAL);

  return (
    <LayoutColumn>
      <StepSidebar steps={invitationSteps} activeStep={activeStep} />
      <Content>
        {activeStep === STEP.FIRST && (
          <InviteUser
            setStepper={setActiveStep}
            setInformation={setInformationState}
            information={informationState}
            nextStep={STEP.SECOND}
          />
        )}
        {activeStep === STEP.SECOND && (
          <ConfirmUser
            setStepper={setActiveStep}
            information={informationState}
            prevStep={STEP.FIRST}
          />
        )}
      </Content>
    </LayoutColumn>
  );
};

const LayoutColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;

const Content = styled.div`
  padding-top: 18px;
  padding-left: 41px;
  flex: 1;
`;
