import React, {useState} from 'react';
import Select, {components, GroupBase, MenuListProps} from 'react-select';
import styled, {CSSObject} from 'styled-components';

import {InputLabel} from '../../../../shared/components/form';
import {colors} from '../../../../shared/styles/theme';
import {stateOptions} from '../../constants/states';

type CustomMenuListProps<Option, IsMulti extends boolean> = MenuListProps<
  Option,
  IsMulti,
  GroupBase<Option>
>;

interface FormikNeededParams {
  errors: {
    state?: string;
  };
  values: {state: string};
  touched: {
    state?: boolean;
  };
  setFieldValue: (field: string, value: any) => void;
}

interface SelectFieldProps {
  name: string;
  formik: FormikNeededParams;
}

const MenuList = ({
  children,
  ...props
}: CustomMenuListProps<typeof Option, boolean>) => (
  <components.MenuList {...props}>
    <MenuHeader>Select State</MenuHeader>
    {children}
  </components.MenuList>
);

export const SelectStateField: React.FC<SelectFieldProps> = ({
  name,
  formik,
}) => {
  const [isError, setIsError] = useState(false);

  const handleChange = (option: {value: string; label: string}) => {
    formik.setFieldValue(name, option.value);
  };

  const handleBlur = () => {
    if (!formik.values.state) {
      setIsError(true);
    }
  };

  return (
    <div>
      <InputLabel error={isError} required>
        State
      </InputLabel>
      <Select
        options={stateOptions}
        onChange={handleChange}
        placeholder="Enter State"
        components={{MenuList}}
        onBlur={handleBlur}
        onFocus={() => setIsError(false)}
        styles={{
          control: (baseStyles: CSSObject, state: {isFocused: boolean}) => ({
            ...baseStyles,
            boxShadow: state.isFocused
              ? `0 0 0 1px ${colors.primary}`
              : undefined,
            borderColor: isError ? colors.red : colors.backgroundPrimary,
            width: 222,
            height: 44,
            cursor: 'text',
            '&:hover': {
              borderColor: colors.black,
            },
          }),
          placeholder: (baseStyles: CSSObject) => ({
            ...baseStyles,
            color: colors.grey1100,
          }),
          indicatorsContainer: () => ({
            opacity: 0,
          }),
          menu: (baseStyles: CSSObject) => ({
            ...baseStyles,
            width: 222,
          }),
          option: (baseStyles: CSSObject) => ({
            ...baseStyles,
            backgroundColor: colors.white,
            color: colors.black,
            '&:hover': {
              backgroundColor: colors.grey700,
            },
          }),
        }}
      />
      {isError && <ErrorDescription>State is required</ErrorDescription>}
    </div>
  );
};

const ErrorDescription = styled.p`
  color: ${colors.red};
  font-style: italic;
  font-size: 12px;
  margin-top: 0px;
`;
const MenuHeader = styled.p`
  font-size: 12px;
  margin-left: 12px;
`;
