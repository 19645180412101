import React from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress} from '@material-ui/core';
import {Form, Formik} from 'formik';
import styled from 'styled-components';

import {Button} from '../../../../shared/components/button';
import {
  INPUT_WIDTH_TYPES,
  InputFormControlWithFormik,
} from '../../../../shared/components/form';
import {ProviderDetailsItem} from '../../constants/providerDetailsItem';

import useProviderSearch from './hooks/useProviderSearch';
import {SearchResults} from './searchResults';
import {SelectStateField} from './selectStateField';

export const SearchComponent: React.FC<{
  isOrganization: boolean;
  setProvider: React.Dispatch<React.SetStateAction<ProviderDetailsItem>>;
}> = ({isOrganization, setProvider}) => {
  const {patientUuid} = useParams<{patientUuid: string}>();
  const {searchResults, isLoading, handleSubmit, validationSchema} =
    useProviderSearch(patientUuid, isOrganization, setProvider);

  return (
    <Container>
      <Formik
        initialValues={{name: '', city: '', state: ''}}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {formik => (
          <Form>
            <FieldWrapper>
              <InputFormControlWithFormik
                required
                id="name"
                name="name"
                label={isOrganization ? 'Organization Name' : 'Provider Name'}
                testIdPrefix="provider-name"
                width={INPUT_WIDTH_TYPES.SMALL}
                error={(formik.touched.name && formik.errors.name) || ''}
                placeholder={
                  isOrganization
                    ? 'Enter organization name'
                    : 'Enter first and last name'
                }
              />
              <InputFormControlWithFormik
                required
                id="city"
                name="city"
                label="City"
                testIdPrefix="city"
                width={INPUT_WIDTH_TYPES.SMALL}
                error={(formik.touched.city && formik.errors.city) || ''}
                placeholder="Enter City"
              />
              <SelectStateField name="state" formik={formik} />
              <ButtonContainer>
                <Button
                  onClick={formik.submitForm}
                  testID="sendCodeButton"
                  type="submit"
                  disabled={!formik.isValid || isLoading}
                >
                  {isLoading ? <CircularProgress size={20} /> : 'Search'}
                </Button>
              </ButtonContainer>
            </FieldWrapper>
          </Form>
        )}
      </Formik>
      {searchResults.length > 0 && (
        <>
          <SearchResults
            searchResults={searchResults}
            isOrganization={isOrganization}
            setProvider={setProvider}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;
const ButtonContainer = styled.div`
  margin-top: 24px;
`;
