import {PatientConnectionsResponse} from '../constants/types';

import {fetchPatientConnections} from './fetchers';

export const getPatientConnections = async (
  consumerUuid: string
): Promise<PatientConnectionsResponse[]> => {
  try {
    const data = await fetchPatientConnections(consumerUuid);

    return data;
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(
        JSON.stringify({
          message: e.message || 'Unknown error occurred',
          status: (e as any).response?.status || 'No status available',
        })
      );
    } else {
      throw new Error('Unknown error occurred');
    }
  }
};
