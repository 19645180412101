import React, {FC} from 'react';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';

interface OrderRecordsOptionsProps {
  iconSrc: string;
  iconSelectedSrc?: string;
  title: string;
  description: string;
  onClick?: () => void;
  selected?: boolean;
  disabled?: boolean;
  isSmall?: boolean;
}

export const OrderRecordItem: FC<OrderRecordsOptionsProps> = ({
  iconSrc,
  iconSelectedSrc,
  title,
  description,
  onClick,
  selected = false,
  disabled = false,
  isSmall = false,
}) => {
  const iconToUse = selected && iconSelectedSrc ? iconSelectedSrc : iconSrc;

  return (
    <OptionsContainer
      className="OptionsContainer"
      onClick={!disabled ? onClick : undefined}
      isSelected={selected}
      disabled={disabled}
      isSmall={isSmall}
    >
      <Icon
        data-testid="selectedIcon"
        src={iconToUse}
        alt="Order Record Icon"
      />
      <TextGroup>
        <TitleText>{title}</TitleText>
        <DescriptionText>{description}</DescriptionText>
      </TextGroup>
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div<{
  isSelected: boolean;
  disabled: boolean;
  isSmall: boolean;
}>`
  width: ${({isSmall}) => (isSmall ? 432 : 464)} px;
  ${({isSmall}) => (isSmall ? 'max-height: 70px ;' : 'height: 102px ;')};
  display: flex;
  flex-direction: row;
  padding: 16px;
  border: 1px solid ${colors.grey1100};
  cursor: pointer;
  border-radius: 4px;

  ${({isSelected}) =>
    isSelected &&
    `
      border: 2px;
      border-color: ${colors.primary};
      box-shadow: 0 0 0 2px ${colors.primary};
    `};
  ${({disabled}) =>
    disabled &&
    `
        background-color: ${colors.grey700};
      `}
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  align-self: flex-start;
  padding-top: 4px;
`;

const TextGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const TitleText = styled.span`
  font-size: 16px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const DescriptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 21px;
  color: ${colors.black};
`;
