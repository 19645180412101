import {ELECTRONIC_RECORD_SECTIONS} from '../components/electronicRecordTab/constants/electronicRecordSections';

export const PORTAL_PATIENTS_ROUTES = {
  CONNECTIONS: '/portal-patients/connections',
  INVITATIONS: '/portal-patients/invitations',
  ORDER_RECORDS: '/portal-patients/order-records',
  MY_CONNECTIONS: '/portal-patients/my-connections',
  UPLOAD_MEDICAL_RECORDS_DATA:
    '/portal-patients/upload-medical-records/:patientUuid',
  MEDICAL_RECORDS_REQUEST:
    '/portal-patients/patient-medical-record-request/:patientUuid',
  CONNECTIONS_PATIENT_PROFILE:
    '/portal-patients/connections/:patientUuid/:patientTab',
  MY_CONNECTIONS_PATIENT_PROFILE:
    '/portal-patients/my-connections/:patientUuid/:patientTab',
  CONNECTIONS_REQUEST_INVITE_PATIENT: '/portal-patients/invitations/invitation',
  CONNECTIONS_INVITE_PATIENT: '/portal-patients/connections/invitation',
  MY_CONNECTIONS_INVITE_PATIENT: '/portal-patients/my-connections/invitation',
  INVITATION_DETAILS: '/portal-patients/invitations/:invitationId',
  PATIENT_TAB: '/portal-patients/:patientTab/:patientUuid',
  CONNECTIONS_ADD_PROVIDER: '/portal-patients/add-provider/:patientUuid',
};

export const DEFAULT_PATIENT_MEDICAL_RECORDS_TAB = `electronic-record/${ELECTRONIC_RECORD_SECTIONS.allergies.path}`;
