import {ConnectionNode} from '../constants/providerDetailsItem';
import {ProviderDirectoryLocation} from '../constants/types';

export const getProviderDirectoryAddress = (
  addressDetails: ProviderDirectoryLocation
): {firstLine?: string; secondLine?: string} => ({
  firstLine: addressDetails.address_1,
  secondLine:
    addressDetails.city && addressDetails.city && addressDetails.state
      ? `${addressDetails.city}, ${addressDetails.state} ${addressDetails.postal_code}`
      : undefined,
});

export const getOrderRecordsAddressConnection = (
  addressDetails: ConnectionNode
): string =>
  `${addressDetails.location_address1},\n${addressDetails.location_city}, ${addressDetails.location_state} ${addressDetails.location_postal_code}`;

export const getOrderRecordsAddress = (
  addressDetails: ProviderDirectoryLocation
): string =>
  `${addressDetails.address_1},\n${addressDetails.city}, ${addressDetails.state} ${addressDetails.postal_code}`;
