import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {Divider, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {CloseButton} from '../../../../shared/components/closeButton';
import {ConfirmModal} from '../../../../shared/components/confirmModal';
import {closeDrawer} from '../../../../shared/components/drawer';
import {
  List,
  ListItemKey,
  ListItemValue,
} from '../../../../shared/components/list';
import {NoDataDash} from '../../../../shared/components/noDataDash';
import {CustomizedCard} from '../../../../shared/customizedCard';
import {useCustomizedSnackbar} from '../../../../shared/hooks/useCustomizedSnackbar';
import {CheckFilledIcon} from '../../../../shared/icons/checkFilledIcon';
import {EditPenIcon} from '../../../../shared/icons/editPenIcon';
import {OrderRecordsIcon} from '../../../../shared/icons/orderRecordsIcon';
import {UncheckFilledIcon} from '../../../../shared/icons/uncheckFilledIcon';
import {colors} from '../../../../shared/styles/theme';
import {DRAWER_DEFAULT_STATE, drawerState} from '../../atoms/drawerState';
import {selectedConnectionState} from '../../atoms/selectedConnection';
import {CONNECTION_STATUSES} from '../../constants/patientStatuses';
import {AvatarSize} from '../../constants/types';
import {editConnectionPreferences} from '../../utils/fetchers';
import {ConnectedPatientStatusLabel} from '../connectedPatientStatusLabel';
import {DrawerHeaderTitle} from '../drawerHeaderTitle';
import {ProfileAvatar} from '../profileAvatar';

import {EditablePreferences} from './editablePreferences';

export const ProviderDetailsDrawer: FC<{
  contentWidth: number;
  fetchData: () => Promise<void>;
}> = ({contentWidth, fetchData}) => {
  const snackbar = useCustomizedSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const setDrawerState = useSetRecoilState(drawerState);
  const [open, setOpen] = useState(false);
  const selectedConnection = useRecoilValue(selectedConnectionState);
  const setSelectedConnection = useSetRecoilState(selectedConnectionState);
  const [receiveRecords, setReceiveRecords] = useState(
    selectedConnection.shareProviderToPatient || false
  );
  const [shareRecords, setShareRecords] = useState(
    selectedConnection.sharePatientToProvider || false
  );

  const hasChanges = useCallback(
    () =>
      selectedConnection.shareProviderToPatient !== receiveRecords ||
      selectedConnection.sharePatientToProvider !== shareRecords,
    [selectedConnection, receiveRecords, shareRecords]
  );

  const onClickClose = useCallback(() => setOpen(false), []);
  const onConfirm = useCallback(() => {
    setDrawerState(DRAWER_DEFAULT_STATE);
  }, [setDrawerState]);

  const onClickOpenModal = useCallback((): void => {
    if (isEditing && hasChanges()) setOpen(true);
    else setDrawerState(DRAWER_DEFAULT_STATE);
  }, [hasChanges, isEditing, setDrawerState]);

  useEffect(() => {
    closeDrawer(onClickOpenModal, ref);
  }, [onClickOpenModal]);

  const handleClickOrderRecords = () => {
    console.log('Order Records button clicked');
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = async () => {
    setIsEditing(false);
    try {
      setSelectedConnection(connection => ({
        ...connection,
        shareProviderToPatient: receiveRecords,
        sharePatientToProvider: shareRecords,
      }));
      await editConnectionPreferences(
        selectedConnection.id,
        selectedConnection.consumerUuid ?? '',
        receiveRecords,
        shareRecords,
        selectedConnection.status ?? ''
      );
    } catch (e) {
      snackbar('Something went wrong. Please try again later.', 'error');
    }
    fetchData();
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setReceiveRecords(selectedConnection.shareProviderToPatient || false);
    setShareRecords(selectedConnection.sharePatientToProvider || false);
  };

  return (
    <ComponentWrapper
      ref={ref}
      width={contentWidth}
      data-testid="provider-details-drawer"
    >
      <Header>
        <TitleWrapper>Provider Details</TitleWrapper>
        <CloseButton
          onClick={onClickOpenModal}
          data-testid="close-drawer-btn"
        />
      </Header>
      <SubHeader>
        <ProfileAvatar
          firstName={selectedConnection.clientFirstName?.split(' ')[0] ?? ''}
          lastName={
            selectedConnection.clientLastName?.split(' ').slice(-1)[0] ?? ''
          }
          size={AvatarSize.small}
        />
        <div>
          <DrawerHeaderTitle title={selectedConnection.nameDisplay ?? ''} />
          <ConnectedPatientStatusLabel
            status={selectedConnection.status ?? CONNECTION_STATUSES.CONNECTED}
          />
        </div>
      </SubHeader>
      <ButtonsWrapper>
        <Button
          color="primary"
          size="large"
          variant="outlined"
          startIcon={<OrderRecordsIcon />}
          data-testid="order-records-btn"
          onClick={handleClickOrderRecords}
        >
          Order Records
        </Button>
      </ButtonsWrapper>
      <List>
        {[
          {
            title: 'Organization',
            value: selectedConnection.relyingPartyName ?? '',
          },
          {
            title: 'Address',
            value: selectedConnection.providerDirectoryLocation ?? '',
          },
          {
            title: 'Date Connected',
            value: selectedConnection.medicalRecordsRequestedAt ?? '',
          },
          {
            title: 'Connected By',
            value: selectedConnection.connectedBy ?? '',
          },
        ].map(({title, value}) => (
          <Fragment key={title}>
            <ListItemKey key={title}>{title}</ListItemKey>
            <ListItemValue key={value}>{value || <NoDataDash />}</ListItemValue>
          </Fragment>
        ))}
      </List>
      <Divider />
      <PatientPreferences>
        <SubTitleWrapper>
          <Title>Patient Preferences</Title>
          {!isEditing && (
            <ButtonWrapper
              color="primary"
              size="large"
              variant="text"
              onClick={handleEditClick}
            >
              <EditPenIcon />
              Edit
            </ButtonWrapper>
          )}
        </SubTitleWrapper>
        {!isEditing ? (
          <CustomizedCard fixedSize={false} size={90}>
            <Wrapper radius="12px 12px 12px 12px">
              <WrapperPermission>
                {selectedConnection.shareProviderToPatient ? (
                  <CheckFilledIcon />
                ) : (
                  <UncheckFilledIcon />
                )}
                <div>
                  <b>Receive</b> records from{' '}
                  <b>{selectedConnection.nameDisplay}</b>
                </div>
              </WrapperPermission>
              <WrapperPermission>
                {selectedConnection.sharePatientToProvider ? (
                  <CheckFilledIcon />
                ) : (
                  <UncheckFilledIcon />
                )}{' '}
                <div>
                  <b>Share</b> records with{' '}
                  <b>{selectedConnection.nameDisplay}</b>
                </div>
              </WrapperPermission>
            </Wrapper>
          </CustomizedCard>
        ) : (
          <EditAttributes>
            <EditablePreferences
              receiveRecords={receiveRecords}
              setReceiveRecords={setReceiveRecords}
              shareRecords={shareRecords}
              setShareRecords={setShareRecords}
            />
            <ButtonsWrapper>
              <div />
              <Button
                color="primary"
                size="large"
                variant="contained"
                disabled={!hasChanges()}
                onClick={() => handleSaveClick()}
              >
                Save Changes
              </Button>
              <Button
                color="primary"
                size="large"
                variant="outlined"
                onClick={handleCancelClick}
              >
                Cancel
              </Button>
            </ButtonsWrapper>
          </EditAttributes>
        )}
      </PatientPreferences>
      <ConfirmModal
        title="Unsaved changes"
        variant="h6"
        isOpen={open}
        onClose={onClickClose}
        onConfirm={onConfirm}
        minWidth={500}
        confirmBtnText="Leave without saving"
      >
        <ConfirmModalContentWrapper>
          <Typography component="p">
            Your changes will be lost if you don’t save them.
          </Typography>
        </ConfirmModalContentWrapper>
      </ConfirmModal>
    </ComponentWrapper>
  );
};

const ConfirmModalContentWrapper = styled.div`
  margin: 16px 0 40px;
`;

const EditAttributes = styled.div``;

const ComponentWrapper = styled.div<{width: number}>`
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 100%;
  box-sizing: border-box;
  padding: 24px 40px;
  width: ${props => `${props.width}px`};
  min-height: 600px;
  height: auto !important;
  height: 600px;
  border-top: 1px solid var(--UI-table-stroke, #e6e6e6);
`;

const SubHeader = styled.div`
  display: inline-grid;
  grid-template-columns: max-content auto;
  grid-gap: 24px;
  margin: 0;
`;

const ButtonsWrapper = styled.div`
  display: contents;
  width: 380px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const ButtonWrapper = styled(Button)`
  color: var(--brand-secondary, #5f7aaf);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;

const TitleWrapper = styled(Typography)`
  font-weight: 600;
  color: ${colors.black};
  font-size: 16px;
`;

const PatientPreferences = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Wrapper = styled.div<{radius: string; noBottom?: boolean}>`
  background-color: ${colors.white};
  border-radius: 12px;
  border: 1px solid ${colors.grey800};
  padding-left: 40px;
  height: auto;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: ${({radius}) => radius};
  border: 1px solid var(--Mobile-UI-Grey-60, #e6e6e6);
  background: var(--Mobile-Background-White, #fff);
  ${({noBottom}) =>
    noBottom &&
    `
    border-bottom-width: 0px;
    border-bottom-style: solid;
  `}
`;

const WrapperPermission = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 8px 0;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
