import React, {FC} from 'react';
import {useLocation} from 'react-router-dom';
import styled from 'styled-components';

import BlueCheckCircle from '../../../../assets/icons/blueCheckCircle.svg';
import SuccessBlueIcon from '../../../../assets/icons/successBlue.svg';
import {Button, CancelButton} from '../../../shared/components/button';
import {colors} from '../../../shared/styles/theme';
import {requestOrderRecords} from '../utils/fetchers';

import {OrderRecordItem} from './orderRecords/orderRecordItem';

type Props = {
  title: string;
  description: string;
  selectedRequestType: string | null;
  selectedDeliveryMethod: string | null;
  onSelectDeliveryMethod: (method: string) => void;
  deliveryNotes: {
    text: string;
    image: string;
  };
  setDeliveryNotes: (note: {text: string; image: string}) => void;
  onNext: () => void;
  onBack: () => void;
  isSmall?: boolean;
};

export const DeliveryMethodForm: FC<Props> = ({
  title,
  description,
  selectedDeliveryMethod,
  onSelectDeliveryMethod,
  deliveryNotes,
  setDeliveryNotes,
  onNext,
  onBack,
  selectedRequestType,
  isSmall = false,
}) => {
  const location = useLocation<{rowData: any; consumerUuid: string}>();
  const {rowData, consumerUuid} = location.state;
  const handlePlaceOrder = async () => {
    const seletedType =
      selectedRequestType === 'BOTH'
        ? ['TEXT', 'IMAGE']
        : [selectedRequestType];
    try {
      if (seletedType.length > 0) {
        seletedType?.map(async type => {
          const note =
            type === 'TEXT' ? deliveryNotes.text : deliveryNotes.image;
          await requestOrderRecords(consumerUuid, rowData.id, [type], note);
        });
      }
    } catch (error) {
      console.error('Error requesting order records', error);
    }
    onNext();
  };

  const renderDeliveryOptions = (
    header: string,
    deliveryMethodOptions: {
      title: string;
      description: string;
      type: string;
      disabled?: boolean;
    }[],
    noteKey: 'text' | 'image'
  ) => (
    <>
      <SubHeader>{header}</SubHeader>
      {deliveryMethodOptions.map(({title, description, type, disabled}) => (
        <OrderRecordItem
          key={type}
          iconSrc={BlueCheckCircle}
          iconSelectedSrc={SuccessBlueIcon}
          title={title}
          description={description}
          selected={selectedDeliveryMethod === type}
          onClick={() => onSelectDeliveryMethod(type)}
          disabled={disabled}
          isSmall={isSmall}
        />
      ))}
      {selectedDeliveryMethod === 'standard' && (
        <Note
          data-testid={`deliveryNote${noteKey}`}
          type="text"
          placeholder="Add your note (optional)"
          value={deliveryNotes[noteKey]}
          onChange={e =>
            setDeliveryNotes({
              ...deliveryNotes,
              [noteKey]: e.target.value,
            })
          }
          maxLength={300}
        />
      )}
    </>
  );

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {(selectedRequestType === 'TEXT' || selectedRequestType === 'BOTH') &&
        renderDeliveryOptions(
          'Order Medical Records',
          [
            {
              title: 'Fast Digital Download',
              description:
                'Receive records immediately by logging in to the provider’s patient portal. We use OneRecord to make this connection.',
              type: 'fast',
              disabled: true,
            },
            {
              title: 'Standard Delivery',
              description:
                'This method can take up to 30 days, but it works for every provider.',
              type: 'standard',
            },
          ],
          'text'
        )}
      {(selectedRequestType === 'IMAGE' || selectedRequestType === 'BOTH') &&
        renderDeliveryOptions(
          'Order Images',
          [
            {
              title: 'Standard Delivery',
              description:
                'This method can take up to 30 days, but it works for every provider.',
              type: 'standard',
            },
          ],
          'image'
        )}
      <ButtonContainer>
        <CancelButton onClick={onBack} />
        <Button onClick={handlePlaceOrder}>Place Order</Button>
      </ButtonContainer>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
`;
const ButtonContainer = styled.div`
  margin-top: 60px;
`;
const Note = styled.input`
  width: 432px;
  height: 41px;
  gap: 8px;
  border-radius: 4px 0px 0px 0px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
  margin-top: -1px;
  border: 1px solid ${colors.backgroundPrimary};
  border-radius: 4px;
  padding-left: 16px;
  padding-right: 16px;
`;
const Title = styled.text`
  font-size: 18px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: -4px;
`;

const Description = styled.text`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 16px;
  color: ${colors.grey801};
  font-style: italic;
`;

const SubHeader = styled.text`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
  margin-bottom: 16px;
`;
