import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const OrderRecordsIcon = props => (
  <SvgIcon {...props} viewBox="0 0 20 16" width="20" height="16">
    <path
      d="M9.45391 7.49345C9.08694 7.49345 8.78945 7.79094 8.78945 8.15791C8.78945 8.52489 9.08694 8.82237 9.45391 8.82237H14.5871L13.0732 10.3363L14.0129 11.276L17.1308 8.15805L14.0129 5.04009L13.0732 5.97978L14.5869 7.49345H9.45391Z"
      fill="#7A7A7A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.89474 15.1579C1.36842 15.1579 0.921053 14.9881 0.552632 14.6486C0.184211 14.3091 0 13.8968 0 13.4117V1.77044C0 1.26922 0.184211 0.848842 0.552632 0.509305C0.921053 0.169769 1.36842 0 1.89474 0H8.28947L10 1.57642H18.1053C18.6316 1.57642 19.0789 1.74619 19.4474 2.08573C19.8158 2.42526 20 2.83756 20 3.32261V13.4117C20 13.8968 19.8158 14.3091 19.4474 14.6486C19.0789 14.9881 18.6316 15.1579 18.1053 15.1579H1.89474ZM1.57895 1.77044V13.4117C1.57895 13.4925 1.60982 13.5614 1.67158 13.6183C1.73263 13.6746 1.80702 13.7027 1.89474 13.7027H18.1053C18.193 13.7027 18.2677 13.6746 18.3295 13.6183C18.3905 13.5614 18.4211 13.4925 18.4211 13.4117V3.32261C18.4211 3.24177 18.3905 3.17289 18.3295 3.11598C18.2677 3.05971 18.193 3.03158 18.1053 3.03158H9.34211L7.65789 1.45516H1.89474C1.80702 1.45516 1.73263 1.48361 1.67158 1.54053C1.60982 1.59679 1.57895 1.67343 1.57895 1.77044Z"
      fill="#7A7A7A"
    />
  </SvgIcon>
);
