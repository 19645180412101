import React, {useCallback, useState} from 'react';
import {CircularProgress, Typography} from '@material-ui/core';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {BaseModalWindow} from '../../../../shared/components/baseModalWindow';
import {Button} from '../../../../shared/components/button';
import {StyledTable} from '../../../../shared/components/table/styledTable';
import {colors} from '../../../../shared/styles/theme';
import {formatInboxEvents} from '../../../inbox/utils/formatInboxEvents';
import {outstandingRecordRequestsModalWindowState} from '../../atoms/outstandingRecordRequestsModalWindowState';
import {getOutstandingRecordRequestsTableCells} from '../../constants/outstandingRecordRequestsTableConfig';

const PROGRESS_SIZE = 22;

type Props = {
  onResolveOutstandingEvents: (eventIds: string[]) => Promise<void>;
  goToDefaultSavePath: () => void;
};
export const OutstandingRecordRequestsModalWindow: React.FC<Props> = ({
  onResolveOutstandingEvents,
  goToDefaultSavePath,
}) => {
  const [selectedEventIds, setSelectedEventIds] = useState<string[]>([]);
  const [isHandling, setIsHandling] = useState<boolean>(false);
  const {isOpen, data} = useRecoilValue(
    outstandingRecordRequestsModalWindowState
  );

  const toggleEvent = useCallback(id => {
    setSelectedEventIds(prevState =>
      prevState.includes(id)
        ? prevState.filter(item => item !== id)
        : [...prevState, id]
    );
  }, []);

  const table = useReactTable({
    data: formatInboxEvents(data ?? []),
    columns: getOutstandingRecordRequestsTableCells({
      selectedEventIds,
      toggleEvent,
    }),
    getCoreRowModel: getCoreRowModel(),
  });

  const handleOutstandingRecords = async () => {
    try {
      setIsHandling(true);
      await onResolveOutstandingEvents(selectedEventIds);
    } finally {
      setIsHandling(false);
      goToDefaultSavePath();
    }
  };

  return (
    <BaseModalWindow
      open={isOpen}
      maxWidth={1583}
      minWidth={1024}
      hasPadding={false}
    >
      <Header>
        <HeaderTitle>
          There are outstanding record requests from this patient in your inbox.
        </HeaderTitle>
      </Header>
      <Body>
        <Typography component="p">
          Do you want to mark these requests as completed? Please check the box
          for each completed item and then click &quot;Save&quot;.
        </Typography>
        <Typography component="p">
          Any unchecked items will remain in your inbox.
        </Typography>

        <StyledTable
          table={table}
          testId="completed-table"
          placeholderComponent={null}
        />
      </Body>
      <Footer>
        <Button
          onClick={handleOutstandingRecords}
          disabled={isHandling}
          testID="resolve-outstanding-requests"
        >
          {isHandling ? <CircularProgress size={PROGRESS_SIZE} /> : 'Save'}
        </Button>
      </Footer>
    </BaseModalWindow>
  );
};

const Header = styled.div`
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
`;

const Body = styled.div`
  border-top: 1px solid ${colors.grey800};
  border-bottom: 1px solid ${colors.grey800};
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Footer = styled.div`
  text-align: right;
  padding: 24px 32px;
`;
