import React, {useCallback, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import {ExitBar} from '../../../shared/components/exitBar';
import {StepSidebar} from '../../../shared/components/stepBar';
import {ConnectionPreferences} from '../components/addProvider/connectionPreferences';
import {OrderRecordNewProvider} from '../components/addProvider/newProviderOrderRecord';
import {ProviderSearch} from '../components/addProvider/providerSearch';
import {SelectLocation} from '../components/addProvider/selectLocation';
import {
  ConnectionNode,
  ProviderDetailsItem,
} from '../constants/providerDetailsItem';

const STEPS = {
  SEARCH_PROVIDER: 'Search for Provider',
  SELECT_LOCATION: 'Select Location',
  CONNECTION_PREFERENCES: 'Connection Preferences',
  ORDER_RECORDS: 'Order Records',
};

const ADD_PROVIDER_STEPS = [
  STEPS.SEARCH_PROVIDER,
  STEPS.SELECT_LOCATION,
  STEPS.CONNECTION_PREFERENCES,
  STEPS.ORDER_RECORDS,
];

export const AddProviderPage: React.FC = () => {
  const history = useHistory();
  const [provider, setProvider] = useState<ProviderDetailsItem>({});
  const [location, setLocation] = useState<ConnectionNode>({});
  const [isOrganization, setIsOrganization] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);
  const goToStep = useCallback(nextStep => {
    const stepIndex = ADD_PROVIDER_STEPS.findIndex(step => step === nextStep);
    setActiveStep(stepIndex);
  }, []);
  const goToNextStep = useCallback(() => {
    if (activeStep < ADD_PROVIDER_STEPS.length) {
      setActiveStep(activeStep + 1);
    }
  }, [activeStep]);

  const isOnStep = useCallback(
    step => ADD_PROVIDER_STEPS[activeStep] === step,
    [activeStep]
  );

  const handleProviderChange = useCallback(() => {
    goToNextStep();
  }, [goToNextStep]);

  const exit = useCallback(() => history.goBack(), [history]);

  return (
    <AddProviderContainer>
      <ExitBar onClick={exit} title="Add Provider" />

      <LayoutColumn>
        <StepSidebar steps={ADD_PROVIDER_STEPS} activeStep={activeStep} />
        <ModalContent>
          {isOnStep(STEPS.SEARCH_PROVIDER) && (
            <ProviderSearch
              onNext={handleProviderChange}
              setProvider={setProvider}
              provider={provider}
              setIsOrganization={setIsOrganization}
              isOrganization={isOrganization}
            />
          )}
          {isOnStep(STEPS.SELECT_LOCATION) && (
            <SelectLocation
              provider={provider}
              location={location}
              onNext={handleProviderChange}
              onBack={() => goToStep(STEPS.SEARCH_PROVIDER)}
              setLocation={setLocation}
              setProvider={setProvider}
              isOrganization={isOrganization}
            />
          )}
          {isOnStep(STEPS.CONNECTION_PREFERENCES) && (
            <ConnectionPreferences
              location={location}
              provider={provider}
              onNext={handleProviderChange}
              onBack={() => goToStep(STEPS.SELECT_LOCATION)}
              isOrganization={isOrganization}
            />
          )}
          {isOnStep(STEPS.ORDER_RECORDS) && (
            <OrderRecordNewProvider
              onBack={() => goToStep(STEPS.CONNECTION_PREFERENCES)}
            />
          )}
        </ModalContent>
      </LayoutColumn>
    </AddProviderContainer>
  );
};

const AddProviderContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const LayoutColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
`;

const ModalContent = styled.div`
  padding: 40px;
  padding-top: 24px;
  flex: 1;
`;
