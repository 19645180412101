import {useCallback, useState} from 'react';
import * as Yup from 'yup';

import {useErrorHandling} from '../../../../../shared/hooks/useErrorHandling';
import {
  ProviderDetailsItem,
  SearchQuery,
} from '../../../constants/providerDetailsItem';
import {fetchProviders} from '../../../utils/fetchers';

const useProviderSearch = (
  patientUuid: string,
  isOrganization: boolean,
  setProvider: React.Dispatch<React.SetStateAction<ProviderDetailsItem>>
): {
  searchResults: ProviderDetailsItem[];
  isLoading: boolean;
  handleSubmit: (values: SearchQuery) => Promise<void>;
  validationSchema: Yup.ObjectSchema<any>;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const handleError = useErrorHandling();
  const [searchResults, setSearchResults] = useState<ProviderDetailsItem[]>([]);
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required(
        // eslint-disable-next-line quotes
        `${isOrganization ? 'Organization' : "Provider's"} name is required`
      ),
    city: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('City is required'),
    state: Yup.string().required('State is required'),
  });
  const handleSubmit = useCallback(
    async (values: SearchQuery) => {
      setIsLoading(true);

      try {
        const response: ProviderDetailsItem[] = await fetchProviders(
          patientUuid,
          isOrganization,
          values
        );
        setProvider({});
        setSearchResults(response);
      } catch (error) {
        if (error instanceof Error) {
          return handleError(error);
        }
      } finally {
        setIsLoading(false);
      }
    },
    [handleError, isOrganization, patientUuid, setProvider]
  );

  return {searchResults, isLoading, handleSubmit, validationSchema};
};

export default useProviderSearch;
