import React from 'react';

import {OrderRecordsPage} from '../../pages/orderRecordsPage';

interface OrderRecordNewProviderProps {
  onBack: () => void;
}

export const OrderRecordNewProvider: React.FC<OrderRecordNewProviderProps> = ({
  onBack,
}) => <OrderRecordsPage navigation={false} onBack={onBack} />;
