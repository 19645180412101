import React from 'react';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

interface ToggleSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked,
  onChange,
}) => {
  const handleToggle = () => {
    onChange(!checked);
  };

  return (
    <Switch>
      <Checkbox
        data-testid="toggle-switch"
        checked={checked}
        onChange={handleToggle}
      />
      <Slider checked={checked} />
    </Switch>
  );
};

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
`;

const Checkbox = styled.input.attrs({type: 'checkbox'})`
  opacity: 0;
  width: 0;
  height: 0;
`;

interface SliderProps {
  checked: boolean;
}

const Slider = styled.span<SliderProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => (props.checked ? colors.green : colors.grey200)};
  transition: 0.4s;
  border-radius: 24px;

    &::before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      border-radius: 50%;
      transition: 0.4s;
      transform: ${props => (props.checked ? 'translateX(24px)' : 'translateX(0)')};
    };
  }
`;
