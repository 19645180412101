import React from 'react';
import {
  Box,
  makeStyles,
  Step,
  StepIcon,
  StepLabel,
  Stepper,
} from '@material-ui/core';

import {colors} from '../styles/theme';

interface ArrowNavigatorProps {
  activeStep: number;
}
const steps = [
  {
    label: 'Select Types of Records',
  },
  {
    label: 'Select Delivery Method',
  },
  {
    label: 'Complete Order',
  },
];

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiStepIcon-active': {
      color: colors.green,
    },
    '& .MuiStepIcon-completed': {
      color: colors.green,
    },
  },
}));

export const StepperNavigator: React.FC<ArrowNavigatorProps> = ({
  activeStep,
}: ArrowNavigatorProps) => {
  const classes = useStyles();
  return (
    <Box
      sx={{width: 300, borderRight: `1px solid ${colors.grey70}`}}
      className={classes.root}
    >
      <Stepper
        activeStep={activeStep}
        style={{paddingTop: 16}}
        orientation="vertical"
      >
        {steps.map(step => (
          <Step key={step.label}>
            <StepLabel
              StepIconComponent={props => (
                <StepIcon
                  {...props}
                  icon={props.icon}
                  active={props.active || props.completed}
                  completed={false}
                />
              )}
            >
              {step.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
