import {atom} from 'recoil';

import {PatientConnectionsResponse} from '../constants/types';
import {getOrderRecordsAddress} from '../utils/getProviderDirectoryAddress';

export interface PatientConnectionType {
  id?: string | number;
  consumerUuid?: string;
  clientId?: string | number;
  nameDisplay?: string;
  clientFirstName?: string;
  clientLastName?: string;
  relyingPartyId?: string | number;
  issuerId?: string | number;
  relyingPartyName?: string;
  providerDirectoryLocationId?: string | number;
  sharePatientToProvider?: boolean;
  shareProviderToPatient?: boolean;
  enableAssistedMode?: boolean;
  initiatingType?: string;
  connectionType?: string;
  status?: string;
  relyingPartyProviderType?: string;
  relyingPartyType?: string;
  medicalRecordsRequestedAt?: string;
  providerDirectoryLocation?: string;
  expiresAt?: string;
  connectedBy?: string;
}
export const fromResponse = (
  response: PatientConnectionsResponse
): PatientConnectionType => ({
  id: response.id,
  consumerUuid: response.consumer_uuid,
  clientId: response.client_id,
  nameDisplay: `${response?.client_first_name} ${response?.client_last_name}`,
  clientFirstName: response.client_first_name,
  clientLastName: response.client_last_name,
  relyingPartyId: response.relying_party_id,
  issuerId: response.issuer_id,
  relyingPartyName: response.relying_party_name,
  providerDirectoryLocationId: response.provider_directory_location_id,
  sharePatientToProvider: response.share_patient_to_provider,
  shareProviderToPatient: response.share_provider_to_patient,
  enableAssistedMode: response.enable_assisted_mode,
  initiatingType: response.initiating_type,
  connectionType: response.connection_type,
  status: response.status,
  relyingPartyProviderType: response.relying_party_provider_type,
  relyingPartyType: response.relying_party_type,
  medicalRecordsRequestedAt: response.medical_records_requested_at,
  providerDirectoryLocation: response.provider_directory_location
    ? getOrderRecordsAddress(response.provider_directory_location)
    : '',
  expiresAt: response.expires_at,
  connectedBy: response.connected_by,
});

const defaultSelectedConnection: PatientConnectionType = {
  id: '',
  consumerUuid: '',
  clientId: '',
  clientFirstName: '',
  clientLastName: '',
  relyingPartyId: '',
  issuerId: '',
  relyingPartyName: '',
  providerDirectoryLocationId: '',
  sharePatientToProvider: false,
  shareProviderToPatient: false,
  enableAssistedMode: false,
  initiatingType: '',
  connectionType: '',
  status: '',
  relyingPartyProviderType: '',
  relyingPartyType: '',
  medicalRecordsRequestedAt: '',
  providerDirectoryLocation: '',
  expiresAt: '',
  connectedBy: '',
};

export const selectedConnectionState = atom<PatientConnectionType>({
  key: 'selectedConnectionState',
  default: defaultSelectedConnection,
});
