import React from 'react';
import styled from 'styled-components';

import {ProviderDirectoryLocation} from '../constants/types';
import {getProviderDirectoryAddress} from '../utils/getProviderDirectoryAddress';

interface Props {
  address: ProviderDirectoryLocation;
}

export const ProviderAddress: React.FC<Props> = ({address}) => {
  const {firstLine, secondLine} = getProviderDirectoryAddress(address);
  return (
    <>
      {firstLine && <AdressLine>{firstLine}</AdressLine>}
      {secondLine && <AdressLine>{secondLine}</AdressLine>}
    </>
  );
};

const AdressLine = styled.p`
  line-height: 8px;
`;
