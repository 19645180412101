import React, {FC} from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../../../shared/styles/theme';

export const DrawerHeaderTitle: FC<{title: string}> = ({title}) => (
  <PatientNameWrapper>{title}</PatientNameWrapper>
);

const PatientNameWrapper = styled(Typography)`
  font-weight: 700;
  color: ${colors.black};
  font-size: 18px;
  margin-bottom: 6px;
`;
