import React from 'react';
import styled from 'styled-components';

import {Button, CancelButton} from '../../../../shared/components/button';
import {InputRadioButton} from '../../../../shared/components/form';
import {colors} from '../../../../shared/styles/theme';
import {ProviderDetailsItem} from '../../constants/providerDetailsItem';

import {SearchComponent} from './searchComponent';

interface ProviderSearchProps {
  onNext: () => void;
  setProvider: React.Dispatch<React.SetStateAction<ProviderDetailsItem>>;
  provider: ProviderDetailsItem;
  isOrganization: boolean;
  setIsOrganization: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ProviderSearch: React.FC<ProviderSearchProps> = ({
  onNext,
  setProvider,
  provider,
  isOrganization,
  setIsOrganization,
}) => (
  <>
    <Title>Add Provider</Title>
    <SubTitle>Please enter the required information.</SubTitle>
    <Header>Choose Provider Type</Header>
    <RadioWrapper>
      <InputRadioButton
        onClick={() => setIsOrganization(false)}
        checked={!isOrganization}
      />
      <RadioLabel style={{marginRight: 20}}>Individual</RadioLabel>
      <InputRadioButton
        onClick={() => setIsOrganization(true)}
        checked={isOrganization}
      />
      <RadioLabel>Organization</RadioLabel>
    </RadioWrapper>
    <Header>Search for {isOrganization ? 'Organization' : 'Provider'}</Header>
    <SearchComponent
      isOrganization={isOrganization}
      setProvider={setProvider}
    />

    <ButtonContainer>
      <CancelButton onClick={() => null}>Cancel</CancelButton>
      <Button
        disabled={Object.keys(provider).length === 0}
        onClick={onNext}
        type="submit"
      >
        Next
      </Button>
    </ButtonContainer>
  </>
);

const ButtonContainer = styled.div`
  margin-top: 40px;
  display: flex;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

const SubTitle = styled.p`
  color: ${colors.grey801};
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 24px;
`;

const Header = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

const RadioLabel = styled.p`
  font-size: 14px;
  font-weight: 400;
`;

const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
