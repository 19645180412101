import {
  MedicalRecordsRequestType,
  PatientConnectionsResponse,
} from '../constants/types';

export const chooseProviderDisplayNames = (
  item: MedicalRecordsRequestType
): {
  nameDisplay: string | undefined;
  organizationDisplay: string | undefined;
} => {
  const isIndividual = item?.recipient === 'CLIENT';

  const nameDisplay = isIndividual
    ? `${item?.client_first_name} ${item?.client_last_name}`
    : item?.client_first_name;

  const organizationDisplay = isIndividual
    ? item?.provider_directory_location?.organization_name
    : undefined;

  return {nameDisplay, organizationDisplay};
};

export const chooseOrderRecords = (
  item: PatientConnectionsResponse
): {
  nameDisplay: string | undefined;
  organizationDisplay: string | undefined;
} => {
  const nameDisplay = `${item?.client_first_name} ${item?.client_last_name}`;

  const organizationDisplay =
    item?.provider_directory_location?.organization_name;

  return {nameDisplay, organizationDisplay};
};
