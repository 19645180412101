import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';

import BackIcon from '../../../assets/icons/back.svg';
import {colors} from '../styles/theme';

interface ArrowNavigatorProps {
  title: string;
}

export const ArrowNavigator: React.FC<ArrowNavigatorProps> = ({
  title,
}: ArrowNavigatorProps) => {
  const history = useHistory();
  const handleGoBack = useCallback(async () => {
    history.goBack();
  }, [history]);
  return (
    <div>
      <NavBar>
        <BackButton
          src={BackIcon}
          onClick={handleGoBack}
          data-testid="backIcon"
        />
        <Title>{title}</Title>
      </NavBar>
    </div>
  );
};

const BackButton = styled.img`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  margin-right: 16px;
  margin-left: 24px;
`;

const Title = styled.text`
  font-size: 18px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
`;

const NavBar = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  border-bottom: 1px solid ${colors.grey70};
`;
