export const exceptionsArray: string[] = [
  'Dr',
  'Prof',
  'Mr',
  'Mrs',
  'Ms',
  'St',
  'Ave',
  'Blvd',
  'Rd',
  'Dr',
  'Inc',
  'Ltd',
  'LLD',
  'LLC',
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
  's',
];

export const capitalizeParser = (
  phrase?: string,
  exceptions: string[] = exceptionsArray
): string => {
  if (phrase) {
    const regex = /(?<=\w)(?=\W)|(?<=\W)(?=\w)|(?<= )/;
    const splittedPhrase: string[] = phrase.split(regex);

    const result = splittedPhrase.map(item => {
      const exception = exceptions.find(
        element => element.toLowerCase() === item.toLowerCase()
      );
      return (
        exception ||
        (item.length > 1
          ? item.charAt(0).toUpperCase() + item.slice(1).toLowerCase()
          : item)
      );
    });

    return result.join('');
  }
  return '';
};
