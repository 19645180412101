import React from 'react';
import {Box} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';

type Props = {
  status: string;
};

const getDisplay = (status: string) => {
  switch (status) {
    case 'REQUESTED':
    case 'FAILED':
      return 'In Progress';
    case 'UPDATE_RECEIVED':
      return 'Completed';
    case 'NO_UPDATES':
      return 'No Records';
    case 'CONNECTION_WAS_DROPPED':
    case 'SHARING_WAS_DISABLED':
    case 'OBSOLETE':
      return 'Cancelled';
    case 'EXPIRED':
      return 'Expired';
    default:
      return 'Unknown';
  }
};

export const RecordOrderStatus = ({status}: Props): JSX.Element => {
  const displayName = getDisplay(status);
  const isCompleted = displayName === 'Completed';

  return (
    <Box>
      <StatusLabel color={isCompleted ? colors.green : colors.grey801}>
        {displayName}
      </StatusLabel>
    </Box>
  );
};

const StatusLabel = styled.p`
  font-size: 14px;
  color: ${props => props?.color};
  font-weight: 700;
  text-transform: capitalize;
`;
