import React, {FC} from 'react';
import {Typography} from '@material-ui/core';
import styled from 'styled-components';

import {CustomizedCard} from '../../../../shared/customizedCard';
import {colors} from '../../../../shared/styles/theme';
import {ToggleSwitch} from '../toggleSwitch';

interface SectionProps {
  title: string;
  subtitle: string;
  checked: boolean;
  onChange: (value: boolean) => void;
}

export const EditablePreferences: FC<{
  receiveRecords: boolean;
  setReceiveRecords: (value: boolean) => void;
  shareRecords: boolean;
  setShareRecords: (value: boolean) => void;
  noBackground?: boolean;
}> = ({
  receiveRecords,
  setReceiveRecords,
  shareRecords,
  setShareRecords,
  noBackground,
}) => {
  const Section: FC<SectionProps> = ({title, subtitle, checked, onChange}) => (
    <div>
      <Wrapper radius="12px 12px 0px 0px" noBottom>
        <SubTitleWrapper>
          <Title>{title}</Title>
          <ToggleSwitch checked={checked} onChange={onChange} />
        </SubTitleWrapper>
      </Wrapper>
      <Wrapper radius="0px 0px 12px 12px">
        <Paragraph>{subtitle}</Paragraph>
      </Wrapper>
    </div>
  );
  const receiveRecordsSection = (
    <Section
      checked={receiveRecords}
      onChange={setReceiveRecords}
      title="Receive records"
      subtitle="Patient is authorizing this organization to send current records and updates to the patient through Health Bank One."
    />
  );
  const shareRecordsSection = (
    <Section
      checked={shareRecords}
      onChange={setShareRecords}
      title="Share records"
      subtitle="Patient is authorizing this organization access to their current records and updates through Health Bank One."
    />
  );
  return noBackground ? (
    <NoBackgroundWrapper>
      {receiveRecordsSection}
      {shareRecordsSection}
    </NoBackgroundWrapper>
  ) : (
    <>
      <CustomizedCard fixedSize={false} size={90}>
        {receiveRecordsSection}
      </CustomizedCard>
      <CustomizedCard fixedSize={false} size={90}>
        {shareRecordsSection}
      </CustomizedCard>
    </>
  );
};

const NoBackgroundWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`;

const Wrapper = styled.div<{radius: string; noBottom?: boolean}>`
  background-color: ${colors.white};
  border-radius: 12px;
  border: 1px solid ${colors.grey800};
  padding-left: 40px;
  height: auto;
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: ${({radius}) => radius};
  border: 1px solid var(--Mobile-UI-Grey-60, #e6e6e6);
  background: var(--Mobile-Background-White, #fff);
  ${({noBottom}) =>
    noBottom &&
    `
    border-bottom-width: 0px;
    border-bottom-style: solid;
  `}
`;

const Title = styled(Typography)`
  font-style: normal;
  line-height: 24px;

  font-feature-settings:
    'liga' off,
    'clig' off;
  font-size: 16px;
  font-weight: 600;
`;

const SubTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Paragraph = styled.div`
  color: var(--text-primary, #323232);
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  opacity: 0.8;
`;
