import React from 'react';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';
import {CHAT_PROMPTS} from '../../constants/careGuidePrompts';

interface Props {
  onClick: (prompt: string) => void;
}

export const SuggestedPrompts: React.FC<Props> = ({onClick}) => (
  <Wrapper>
    {Array.from(CHAT_PROMPTS.keys()).map((prompt, index) => (
      <Container key={index}>
        <Text onClick={() => onClick(prompt)} style={{cursor: 'pointer'}}>
          {prompt}{' '}
        </Text>
      </Container>
    ))}
  </Wrapper>
);

const Wrapper = styled.div`
  gap: 16px;
  display: flex;
  padding: 30px 40px;
  flex-direction: column;
  align-items: flex-start;
  width: 85%;
`;

const Container = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  align-self: stretch;
  background: ${colors.grey700};
  border-radius: 5px;
`;

const Text = styled.button`
  background: none;
  display: flex;
  padding: 16px 20px;
  text-align: left;
  gap: 14px;
  border: none;
  font-family: 'Open Sans';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;
