import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import cx from 'classnames';
import styled from 'styled-components';

import {colors} from '../styles/theme';

import {NoDataDash} from './noDataDash';

const useListItemKeyStyles = makeStyles(() => ({
  root: {
    color: colors.grey801,
    lineHeight: '24px',
    wordWrap: 'break-word',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const useListItemValueStyles = makeStyles(() => ({
  root: {
    textAlign: 'left',
    color: colors.black,
    fontWeight: 600,
    margin: 0,
    lineHeight: '24px',
    wordWrap: 'break-word',
    '&:first-letter': {
      textTransform: 'uppercase',
    },
  },
}));

const useListTitleRowStyles = makeStyles(() => ({
  dt: {
    color: 'rgba(50, 50, 50, 0.70)',
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '24px',
  },
  dd: {
    lineHeight: '24px',
  },
}));

export const List = styled.dl<{labelsColumnWidth?: string; width?: string}>`
  display: inline-grid;
  grid-template-columns: ${props =>
    props?.labelsColumnWidth
      ? `${props.labelsColumnWidth} auto`
      : 'max-content auto'};
  grid-gap: 8px;
  margin: 0;
  width: ${props => props?.width ?? undefined};
  height: fit-content;
`;

export const ListItemKey: React.FC<{
  classNames?: string;
  children?: React.ReactNode;
  testId?: string | null;
}> = ({children, classNames, testId}) => {
  const {root} = useListItemKeyStyles();

  return (
    <dt className={cx(root, classNames)} data-testid={testId}>
      {children ?? <NoDataDash />}
    </dt>
  );
};

export const ListItemValue: React.FC<{
  classNames?: string;
  children?: React.ReactNode;
  testId?: string | null;
}> = ({children, classNames, testId = null}) => {
  const {root} = useListItemValueStyles();

  return (
    <dd className={cx(root, classNames)} data-testid={testId}>
      {children ?? <NoDataDash />}
    </dd>
  );
};

export const ListTitleRow: React.FC<{
  title?: string | null;
  testId?: string | null;
}> = ({title, testId = null}) => {
  const {dt} = useListTitleRowStyles();

  return (
    <>
      <dt className={dt} data-testid={testId}>
        {title ?? <NoDataDash />}
      </dt>
      <dd className={dt} />
    </>
  );
};

export const ListNoDataRow: React.FC<{
  testId?: string | null;
  noDataMessage?: string | null;
}> = ({testId = 'no-data', noDataMessage}) => {
  const valueClasses = useListItemValueStyles();

  return (
    <>
      {noDataMessage ? (
        <ListItemKey testId={testId}>{noDataMessage}</ListItemKey>
      ) : (
        <dt className={valueClasses.root} data-testid={testId}>
          <NoDataDash />
        </dt>
      )}
      <Box component="dd" lineHeight="24px" />
    </>
  );
};
