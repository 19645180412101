import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const CheckFilledIcon: React.FC<any> = props => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM14.5378 7.4482C14.7853 7.1512 14.7452 6.70981 14.4482 6.46231C14.1512 6.21482 13.7098 6.25494 13.4623 6.55194L8.95301 11.9631L6.49502 9.50509C6.22166 9.23172 5.77844 9.23172 5.50507 9.50509C5.23171 9.77846 5.23171 10.2217 5.50507 10.495L8.50507 13.495C8.64414 13.6341 8.8353 13.7083 9.03177 13.6993C9.22824 13.6904 9.4119 13.5993 9.5378 13.4482L14.5378 7.4482Z"
      fill="#268E6C"
    />
  </SvgIcon>
);
