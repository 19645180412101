import React, {useEffect, useState} from 'react';
import {Link, List, ListItem, makeStyles} from '@material-ui/core';
import styled from 'styled-components';

import {colors} from '../../../../shared/styles/theme';
import {capitalizeParser} from '../../../../shared/utils/captalizeParser';
import {
  ConnectionNode,
  ProviderDetailsItem,
} from '../../constants/providerDetailsItem';

import {MissingProviderModal} from './missingProviderModal';

interface Props {
  searchResults?: ProviderDetailsItem[];
  locations?: ConnectionNode[];
  isLocations?: boolean;
  isOrganization?: boolean;
  setLocation?: React.Dispatch<React.SetStateAction<ConnectionNode>>;
  setProvider?: React.Dispatch<React.SetStateAction<ProviderDetailsItem>>;
}

interface SelectableItem {
  selected: boolean;
  key: string;
  onClick: () => void;
  title: string;
  subtitle: string;
  isConnected?: boolean;
}

const useListItemStyles = makeStyles(() => ({
  root: {
    fontFamily: 'Open Sans',
    padding: '8px 12px',
    borderRadius: '4px',
    backgroundColor: colors.white,
    border: `1px solid ${colors.backgroundPrimary}`,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '21px',
    marginBottom: '4px',
    '&:last-child': {
      marginBottom: 0,
    },
    color: colors.grey801,
  },
  selected: {
    backgroundColor: 'rgba(36, 71, 130, 0.05) !important',
    fontWeight: 700,
    borderRadius: '4px',
    border: `2px solid ${colors.primary}`,
    boxShadow: '0px 4px 10px 0px rgba(122, 122, 122, 0.15)',
    color: colors.grey600,
  },
  dividerText: {
    fontSize: '12px',
    fontWeight: 600,
    marginLeft: 24,
    marginTop: 24,
    marginBottom: 24,
    color: colors.primary,
  },
  listWrapper: {
    backgroundColor: colors.grey1000,
    padding: 16,
    gap: 10,
    maxWidth: '674px',
    maxHeight: '330px',
    overflow: 'auto',
  },
}));

const toPascal = (s: string) =>
  s.replace(
    /(\w)(\w*)/g,
    (match, p1, p2) => p1.toUpperCase() + p2.toLowerCase()
  );

export const SearchResults: React.FC<Props> = ({
  searchResults = null,
  isOrganization,
  setProvider,
  locations = null,
  isLocations = false,
  setLocation,
}) => {
  const listItemStyles = useListItemStyles();
  const [selectedProvider, setSelectedProvider] = useState<ProviderDetailsItem>(
    {}
  );
  const [openModal, setOpenModal] = useState(false);

  const [selectedLocation, setSelectedLocation] = useState<ConnectionNode>({});

  useEffect(() => {
    setSelectedProvider({});
  }, [searchResults, locations]);

  const ListSearch = ({
    selected,
    key,
    onClick,
    title,
    subtitle,
    isConnected = false,
  }: SelectableItem): JSX.Element => (
    <ListItem
      selected={selected}
      button
      classes={{
        root: listItemStyles.root,
        selected: listItemStyles.selected,
      }}
      key={key}
      onClick={onClick}
      disabled={isConnected}
    >
      <Item>
        <div>
          {capitalizeParser(title)}
          <SubItem>{subtitle}</SubItem>
        </div>
        {isConnected && <ConnectedText>Connected</ConnectedText>}
      </Item>
    </ListItem>
  );
  const titleProvider = isOrganization ? 'Organization' : 'Provider';

  const searchResultItems = searchResults?.map(item => (
    <ListSearch
      selected={item.provider_npi === selectedProvider.provider_npi}
      key={item?.provider_npi ?? ''}
      onClick={() => {
        setSelectedProvider(item);
        setProvider?.(item);
      }}
      title={item.provider_name ?? ''}
      subtitle={formatProviderAddresses(item) ?? ''}
    />
  ));
  const locationsItems = locations?.map(item => (
    <ListSearch
      selected={item.location_address1 === selectedLocation.location_address1}
      key={item?.location_address1 ?? ''}
      onClick={() => {
        setSelectedLocation(item);
        setLocation?.(item);
      }}
      title={item.location_address1 ?? ''}
      subtitle={item.location_address1 ?? ''}
      isConnected={item.connection_status === 'CONNECTED'}
    />
  ));

  return (
    <Container>
      <Header>Select {isLocations ? 'Location' : titleProvider}</Header>
      <List classes={{root: listItemStyles.listWrapper}}>
        {isLocations ? locationsItems : searchResultItems}
      </List>
      <LinkContainer>
        <Link
          style={{color: colors.blue, alignSelf: 'end'}}
          href="/#"
          onClick={e => {
            e.preventDefault();
            setOpenModal(true);
          }}
          underline="always"
        >
          Report missing
          {isLocations ? ' location' : ` ${titleProvider.toLowerCase()}`}
        </Link>
      </LinkContainer>
      <MissingProviderModal
        isOrganization={isOrganization}
        isOpen={openModal}
        setOpenModal={setOpenModal}
      />
    </Container>
  );

  function formatProviderAddresses(item: ProviderDetailsItem): string {
    return Array.from(
      new Set<string>(
        item?.addresses?.map(
          address => `${toPascal(address.city ?? '')}, ${address.state}`
        )
      )
    ).join(' | ');
  }
};

const Header = styled.p`
  font-size: 14px;
  font-weight: 700;
`;

const LinkContainer = styled.div`
  max-width: 706px;
  display: flex;
  flex-direction: column-reverse;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const SubItem = styled.div`
  color: ${colors.grey600};
  font-weight: 400;
`;
const Item = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1 0 0;
`;

const ConnectedText = styled.div`
  color: var(--UI-green, #268e6c);

  /* header/3rd */
  font-family: 'Open Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 21px; /* 150% */
`;
