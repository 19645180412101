import React, {useCallback, useMemo} from 'react';
import {
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useField} from 'formik';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {ChipsWrapperForInput} from '../../../shared/components/chip';
import {InputHelperText, InputLabel} from '../../../shared/components/form';
import {InfoTooltip} from '../../../shared/components/infoTooltip';
import {
  ROLES,
  ROLES_DESCRIPTIONS,
  ROLES_LABEL,
} from '../../../shared/constants/roles';
import {ANCHOR} from '../../../shared/styles/theme';
import {formatRoles} from '../../../shared/utils/formatRoles';
import {getPermissionRoles} from '../utils/getPermissionRoles';

const formatRolesAsChips = (selectedRoles, setValue, roles) => {
  const onDeleteChip = (e, selectedRole) => {
    e.preventDefault();

    setValue(roles.filter(role => role !== selectedRole));
  };

  return (
    <ChipsWrapperForInput>
      {selectedRoles.map(selectedRole => (
        <ChipItem
          key={selectedRole}
          selectedRole={selectedRole}
          onDeleteChip={onDeleteChip}
        />
      ))}
    </ChipsWrapperForInput>
  );
};

const formatRolesAsString = roles => (
  <SelectTextContentWrapper>{formatRoles(roles)}</SelectTextContentWrapper>
);

export const RolesSelect = ({
  currentUserRoles,
  openedUserRoles,
  disabled,
  testID,
  ...props
}) => {
  const [field, meta, {setValue}] = useField(props);

  const isError = !!meta.touched && !!meta.error;
  const onClick = useCallback(e => e.preventDefault(), []);

  const listOfItems = useMemo(
    () =>
      getPermissionRoles(currentUserRoles).map(role => (
        <MenuItem key={role} value={role} onClick={onClick}>
          <FormControlLabel
            control={
              <Checkbox color="primary" checked={field.value.includes(role)} />
            }
            label={ROLES_LABEL[role]}
          />
        </MenuItem>
      )),
    [currentUserRoles, onClick, field.value]
  );

  const tooltipDescription = useMemo(() => {
    const permissions = getPermissionRoles(currentUserRoles);

    return (
      <>
        {permissions.map(permission => (
          <p key={permission}>
            <b>{ROLES_LABEL[permission]}: </b>
            {ROLES_DESCRIPTIONS[permission]}
          </p>
        ))}
      </>
    );
  }, [currentUserRoles]);

  const renderValue = useCallback(
    selected =>
      disabled
        ? formatRolesAsString(openedUserRoles)
        : formatRolesAsChips(selected, setValue, field.value),

    [disabled, field.value, openedUserRoles, setValue]
  );

  return (
    <FormControl error={isError} disabled={disabled}>
      <InputLabel htmlFor="role-label">Assign Role</InputLabel>
      <RolesSelectWrapper>
        <SelectWrapper
          multiple
          IconComponent={ExpandMoreIcon}
          id="role"
          labelId="role-label"
          variant="outlined"
          MenuProps={ANCHOR}
          inputProps={{'data-testid': testID}}
          renderValue={renderValue}
          {...field}
          {...props}
        >
          {listOfItems}
        </SelectWrapper>
        <InfoTooltip description={tooltipDescription} />
      </RolesSelectWrapper>
      <InputHelperText shrink>{isError ? meta.error : ''}</InputHelperText>
    </FormControl>
  );
};

RolesSelect.propTypes = {
  disabled: PropTypes.bool,
  testID: PropTypes.string,
  currentUserRoles: PropTypes.arrayOf(PropTypes.string),
  openedUserRoles: PropTypes.arrayOf(PropTypes.string),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
    roles: PropTypes.arrayOf(PropTypes.string),
    party: PropTypes.string,
    relyingParty: PropTypes.shape({
      id: PropTypes.number,
    }),
  }),
};

const SelectWrapper = styled(Select)`
  width: 464px;
`;

const SelectTextContentWrapper = styled.div`
  word-break: break-word;
  white-space: normal;
  padding: 7px 0;
`;

const RolesSelectWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
function ChipItem({selectedRole, onDeleteChip}) {
  const onMouseDown = useCallback(e => e.stopPropagation(), []);
  const onDelete = useCallback(
    e => onDeleteChip(e, selectedRole),
    [onDeleteChip, selectedRole]
  );

  return (
    <Chip
      key={selectedRole}
      label={ROLES_LABEL[selectedRole]}
      size="small"
      deleteIcon={<CancelIcon onMouseDown={onMouseDown} />}
      onDelete={onDelete}
    />
  );
}

ChipItem.propTypes = {
  selectedRole: PropTypes.oneOf(Object.values(ROLES)),
  onDeleteChip: PropTypes.func,
};
