import React from 'react';
import {SvgIcon} from '@material-ui/core';

export const UncheckFilledIcon: React.FC<any> = props => (
  <SvgIcon
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9.99999 9.31768L12.7462 6.57143L13.4286 7.25374L10.6819 10L13.4286 12.7463L12.7462 13.4286L9.99999 10.6823L7.25335 13.4286L6.57141 12.7463L9.31767 10L6.57141 7.25374L7.25335 6.57143L9.99999 9.31768Z"
      fill="#EB5757"
    />
  </SvgIcon>
);
