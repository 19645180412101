import React from 'react';
import {SvgIcon} from '@material-ui/core';

export function EditPenIcon(props) {
  return (
    <SvgIcon
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3732 3.59349L16.408 4.62904C16.7871 5.00811 17 5.52223 17 6.0583C17 6.59438 16.7871 7.1085 16.408 7.48757L8.0575 15.8381C7.95312 15.9426 7.8115 16.0014 7.66379 16.0015H4.55715C4.40938 16.0015 4.26767 15.9428 4.16318 15.8383C4.0587 15.7338 4 15.5921 4 15.4443V12.3384C4.00013 12.1907 4.05891 12.0491 4.16343 11.9447L12.5132 3.59275C12.7009 3.40484 12.9238 3.25577 13.1692 3.15406C13.4146 3.05235 13.6776 3 13.9432 3C14.2088 3 14.4718 3.05235 14.7172 3.15406C14.9625 3.25577 15.1855 3.40484 15.3732 3.59275V3.59349ZM10.9888 6.69345L5.11429 12.5695V14.8872H7.43201L13.3073 9.01117L10.9896 6.69345H10.9888ZM13.3014 4.38167L11.7777 5.90527L14.0955 8.223L15.6198 6.70013C15.7042 6.61589 15.7711 6.51584 15.8168 6.40571C15.8624 6.29558 15.8859 6.17752 15.8859 6.0583C15.8859 5.93908 15.8624 5.82102 15.8168 5.71089C15.7711 5.60076 15.7042 5.50071 15.6198 5.41647L14.5858 4.38167C14.5015 4.29741 14.4015 4.23058 14.2915 4.18498C14.1814 4.13938 14.0634 4.11591 13.9443 4.11591C13.8252 4.11591 13.7072 4.13938 13.5971 4.18498C13.4871 4.23058 13.3871 4.29741 13.3028 4.38167H13.3014Z"
        fill="#5F7AAF"
      />
    </SvgIcon>
  );
}
