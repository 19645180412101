import React from 'react';
import {ColumnDef} from '@tanstack/react-table';
import {SetterOrUpdater} from 'recoil';
import styled from 'styled-components';

import {NoDataDash} from '../../../shared/components/noDataDash';
import {ActionTableButton} from '../../../shared/components/table/actionTableButton';
import {ActionIcon} from '../../../shared/icons/actionIcon';
import {DisconnectIcon} from '../../../shared/icons/disconnectIcon';
import {OrderRecordsIcon} from '../../../shared/icons/orderRecordsIcon';
import {AdditionalTableProps} from '../../../shared/interfaces/table';
import {fromResponse, PatientConnectionType} from '../atoms/selectedConnection';
import {chooseOrderRecords} from '../utils/chooseProviderName';
import {getOrderRecordsAddress} from '../utils/getProviderDirectoryAddress';

import {DrawerStateType, DrawerTypes, PatientConnections} from './types';

export const getOrderRecordsTableCells = (
  onClikOrderRecords: (rowData: PatientConnections) => void,
  setDrawerState: SetterOrUpdater<DrawerStateType>,
  setSelectedConnection?: SetterOrUpdater<PatientConnectionType>
): (ColumnDef<PatientConnections> & AdditionalTableProps)[] => [
  {
    id: 'connectedProviders',
    header: 'Connected Providers',
    width: '240px',
    minWidth: '220px',
    isStickyLeft: true,
    cell({row: {original}}) {
      const {nameDisplay} = chooseOrderRecords(original);
      return nameDisplay;
    },
  },
  {
    id: 'organization',
    header: 'Organization',
    cell({row: {original}}) {
      const {organizationDisplay} = chooseOrderRecords(original);
      return organizationDisplay ?? <NoDataDash />;
    },
    width: '272px',
    minWidth: '272px',
  },
  {
    id: 'address',
    header: 'Address',
    cell({row: {original}}) {
      const address =
        original.provider_directory_location &&
        getOrderRecordsAddress(original.provider_directory_location);
      return address ?? <NoDataDash />;
    },
    width: '272px',
    minWidth: '272px',
  },
  {
    id: 'connectedBy',
    header: 'Connected By',
    cell({row: {original}}) {
      return original.connected_by ?? <NoDataDash />;
    },
    minWidth: '160px',
  },
  {
    id: 'actions',
    isStickyRight: true,
    header: 'Actions',
    width: '172px',
    cell({row: {original}}): JSX.Element {
      return (
        <ActionsWrapper className="order-records-table-action-wrapper">
          <ActionTableButton
            title="View Details"
            testId="Detail-icon"
            onClick={() => {
              setDrawerState({
                isOpen: true,
                patientUuid: null,
                currentDrawer: DrawerTypes.PROVIDER_DETAILS,
              });
              setSelectedConnection?.(fromResponse(original));
            }}
          >
            <ActionIcon />
          </ActionTableButton>
          <ActionTableButton
            title="Order Records"
            testId="order-records-icon"
            onClick={() => onClikOrderRecords(original)}
          >
            <OrderRecordsIcon />
          </ActionTableButton>
          <ActionTableButton
            title="Disconnect Provider"
            testId="disconnect-provider-icon"
            onClick={() => {
              console.log('Disconnect Provider');
            }}
          >
            <DisconnectIcon />
          </ActionTableButton>
        </ActionsWrapper>
      );
    },
  },
];

const ActionsWrapper = styled.div`
  &.order-records-table-action-wrapper {
    display: flex;
    align-items: center;
    justify-content: normal;
  }
`;
