import React, {useCallback} from 'react';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import PropTypes from 'prop-types';
import {useRecoilValue} from 'recoil';
import styled from 'styled-components';

import {currentUserState} from '../../../shared/atoms/authAtom';
import {Button} from '../../../shared/components/button';
import {DataDisplayField} from '../../../shared/components/dataDisplayField';
import {Description} from '../../../shared/components/description';
import {PARTY, ROLES_LABEL} from '../../../shared/constants/roles';
import {
  USER_TYPES,
  USER_TYPES_LABELS,
} from '../../../shared/constants/userTypes';
import {useCustomizedSnackbar} from '../../../shared/hooks/useCustomizedSnackbar';
import {useErrorHandling} from '../../../shared/hooks/useErrorHandling';
import {colors} from '../../../shared/styles/theme';
import {fetcher} from '../../../shared/utils/fetcher';
import {formatPhoneNumber} from '../../../shared/utils/formatPhoneNumber';
import {urls} from '../../../shared/utils/urls';
import FieldsInfo from '../../relyingPartyInvitation/components/fieldsInfo';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
      backgroundColor: `${colors.grey1000}`,
    },
  },
}));

const mapUserInformationToRequestBody = information => ({
  email: information.email,
  roles: information.role.join(','),
  type: information.userType,
  npi: information.npi,
  first_name: information.firstName,
  last_name: information.lastName,
  phone_number: information.phoneNumber,
});

export const ConfirmUser = ({information, setStepper, prevStep}) => {
  const classes = useStyles();
  const history = useHistory();
  const {relyingParty, party} = useRecoilValue(currentUserState);

  const showMessage = useCustomizedSnackbar();
  const handleError = useErrorHandling(
    'Failed to invite the user. Please try again.'
  );

  const url =
    party === PARTY.ISSUER
      ? urls.issuerPortalUsers(relyingParty.id)
      : urls.relyingPartyInviteUser(relyingParty.id);

  const handleInvite = useCallback(async () => {
    await fetcher(url, 'POST', mapUserInformationToRequestBody(information))
      .then(() => {
        history.push('/portal-users');
        showMessage('Invitation sent successfully.', 'success');
      })
      .catch(handleError);
  }, [url, handleError, history, information, showMessage]);

  const onClick = useCallback(
    () => setStepper(prevStep),
    [prevStep, setStepper]
  );

  return (
    <>
      <Description
        title="Confirm invitation"
        description="Submit the following information to invite a new user to this admin portal"
      />
      <form className={classes.root}>
        {party === PARTY.RELYING_PARTY ? (
          <FieldsInfo
            title="Contact Information"
            fields={
              information.userType === USER_TYPES.PHYSICIAN
                ? [
                    {
                      label: 'User type',
                      value: USER_TYPES_LABELS[information.userType],
                    },
                    {label: 'NPI', value: information.npi},
                  ]
                : [
                    {
                      label: 'User type',
                      value: USER_TYPES_LABELS[information.userType],
                    },
                  ]
            }
          />
        ) : null}

        <FieldsInfo
          title={party === PARTY.ISSUER ? 'Contact Information' : null}
          fields={[
            {label: 'First name', value: information.firstName},
            {label: 'Last name', value: information.lastName},
          ]}
        />
        <FieldsInfo
          fields={[
            {label: 'Email address', value: information.email},
            {
              label: 'Phone number',
              value: formatPhoneNumber(information.phoneNumber),
            },
          ]}
        />
        <PermissionTitle>Permission</PermissionTitle>
        <DataDisplayField
          label="Role"
          value={information.role.map(item => ROLES_LABEL[item]).join('; ')}
          size="large"
        />
        <ButtonWrapper>
          <Button variant="outlined" onClick={onClick}>
            Back
          </Button>
          <Button onClick={handleInvite} testID="inviteBtn">
            Invite
          </Button>
        </ButtonWrapper>
      </form>
    </>
  );
};

ConfirmUser.propTypes = {
  information: PropTypes.shape({
    npi: PropTypes.string,
    userType: PropTypes.string,
    phoneNumber: PropTypes.string,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.arrayOf(PropTypes.string),
  }),
  setStepper: PropTypes.func,
  prevStep: PropTypes.number,
};

const PermissionTitle = styled.p`
  margin: 30px 0 8px;
  font-weight: 700;
  line-height: 24px;
  font-size: 14px;
  color: ${colors.black};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 160px;
`;
