import React, {FC, useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {useRecoilValue, useSetRecoilState} from 'recoil';
import styled from 'styled-components';

import {AddButton} from '../../../../shared/components/button';
import {Drawer} from '../../../../shared/components/drawer';
import {LoadingBar} from '../../../../shared/components/loadingBar';
import {NoDataPlaceholder} from '../../../../shared/components/noDataPlaceholder';
import {SmthWentWrongPlaceholder} from '../../../../shared/components/smthWentWrongPlaceholder';
import {StyledTable} from '../../../../shared/components/table/styledTable';
import {drawerState} from '../../atoms/drawerState';
import {selectedConnectionState} from '../../atoms/selectedConnection';
import {getOrderRecordsTableCells} from '../../constants/orderRecordsTableConfig';
import {PORTAL_PATIENTS_ROUTES} from '../../constants/routes';
import {
  DrawerTypes,
  PatientConnections,
  PatientConnectionsResponse,
} from '../../constants/types';
import {getPatientConnections} from '../../utils/getPatientConnections';
import {ProviderDetailsDrawer} from '../addProvider/providerDetailsDrawer';
import {PermissionDenied} from '../permissionDenied';

type Props = {
  testId: string;
  consumerUuid: string;
};
const DRAWER_WIDTH = 460;

export const OrderRecordsTab: FC<Props> = ({testId, consumerUuid}) => {
  const [data, setData] = useState<PatientConnectionsResponse[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState(0);
  const history = useHistory();

  const onClikOrderRecords = useCallback(
    (rowData: PatientConnections) => {
      history.push({
        pathname: PORTAL_PATIENTS_ROUTES.ORDER_RECORDS,
        state: {rowData, consumerUuid},
      });
    },
    [consumerUuid, history]
  );

  const setDrawerState = useSetRecoilState(drawerState);
  const drawer = useRecoilValue(drawerState);
  const setSelectedConnection = useSetRecoilState(selectedConnectionState);

  const columns = getOrderRecordsTableCells(
    onClikOrderRecords,
    setDrawerState,
    setSelectedConnection
  );

  const fetchDataNoLoading = useCallback(async () => {
    setIsError(false);
    try {
      const result = await getPatientConnections(consumerUuid);
      setData(result || []);
    } catch (error) {
      setIsError(true);
    }
  }, [consumerUuid]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await getPatientConnections(consumerUuid);
      setIsLoading(false);
      setData(result || []);
    } catch (error) {
      setError(JSON.parse((error as Error).message).status);
      setIsError(true);
      setIsLoading(false);
    }
  }, [consumerUuid]);

  useEffect(() => {
    fetchData();
  }, [consumerUuid, fetchData]);

  const table = useReactTable<PatientConnectionsResponse>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const renderErrorPlaceholder = () => {
    if (!isError) return null;

    return error === 403 ? (
      <PermissionDenied errorMessageFrom="order records" />
    ) : (
      <SmthWentWrongPlaceholder
        labelText="Something went wrong during request processing. Please retry."
        onClick={() => window.location.reload()}
      />
    );
  };

  return (
    <TabWrapper data-testid={testId}>
      <LoadingBar loading={isLoading} />
      {isError && (
        <CenteringErrorPlaceholder>
          {renderErrorPlaceholder()}
        </CenteringErrorPlaceholder>
      )}
      {!isLoading && !isError && (
        <>
          {data.length === 0 ? (
            <NoDataPlaceholderWrapper>
              <NoDataPlaceholder noDataText="There are no order records available." />
            </NoDataPlaceholderWrapper>
          ) : (
            <TableWrapper>
              <HeaderWrapper>
                <ButtonBox>
                  <TextAddButton>
                    Need records from another provider?
                  </TextAddButton>
                  <AddButton
                    text="Add Provider"
                    onClick={() =>
                      history.push(
                        PORTAL_PATIENTS_ROUTES.CONNECTIONS_ADD_PROVIDER.replace(
                          ':patientUuid',
                          consumerUuid
                        )
                      )
                    }
                    data-testid="addProviderButton"
                  />
                </ButtonBox>
              </HeaderWrapper>
              <Container>
                <StyledTable
                  table={table}
                  testId="order-records-table"
                  placeholderComponent={null}
                />
                {drawer.currentDrawer === DrawerTypes.PROVIDER_DETAILS && (
                  <DrawerContainer id="provider-details" />
                )}
              </Container>{' '}
            </TableWrapper>
          )}
        </>
      )}
      <Drawer
        drawerWidth={DRAWER_WIDTH}
        data-testid="provider-details-drawer"
        elementId="provider-details"
      >
        {drawer.currentDrawer === DrawerTypes.PROVIDER_DETAILS && (
          <ProviderDetailsDrawer
            contentWidth={DRAWER_WIDTH}
            fetchData={fetchDataNoLoading}
          />
        )}
      </Drawer>{' '}
    </TabWrapper>
  );
};

const DrawerContainer = styled.div``;

const Container = styled.div`
  display: flex;
  flex: 1;
  overflow: auto;
`;

const TabWrapper = styled.div`
  position: relative;
`;

const TableWrapper = styled.div`
  white-space: break-spaces;
  margin: 20px 0 24px 0;
  .MuiIconButton-sizeSmall {
    padding: 1px;
  }
  .MuiTableCell-root.MuiTableCell-body.stickyRight {
    padding-left: 26px;
  }
  .MuiTableRow-root.MuiTableRow-hover {
    height: 80px;
  }
  .MuiTableRow-root.MuiTableRow-head {
    height: 56px;
  }

  .MuiSvgIcon-root {
    width: 20px;
    height: 20px;
    padding: 6px;
  }
`;

const NoDataPlaceholderWrapper = styled.div`
  margin-bottom: 80px;
`;

const CenteringErrorPlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 0;
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 32px;
`;

const ButtonBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const TextAddButton = styled(Typography)`
  font-size: 14px;
  font-weight: 400;
  text-align: right;
`;
