import React, {FC, useCallback, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from 'styled-components';

import BlueCheckCircle from '../../../../../assets/icons/blueCheckCircle.svg';
import SuccessBlueIcon from '../../../../../assets/icons/successBlue.svg';
import {Button, CancelButton} from '../../../../shared/components/button';
import {colors} from '../../../../shared/styles/theme';
import {MainHeader} from '../../../layout/components/mainHeader';
import {chooseProviderDisplayNames} from '../../utils/chooseProviderName';
import {
  getOrderRecordsAddress,
  getOrderRecordsAddressConnection,
} from '../../utils/getProviderDirectoryAddress';

import {OrderRecordItem} from './orderRecordItem';

type Props = {
  title: string;
  description: string;
  selectedIndex: string | null;
  onSelectIndex: (index: string) => void;
  onNext: () => void;
  hasSkipButton?: boolean;
};

enum ProviderTypes {
  ORGANIZATION = 'ORGANIZATION',
  EXTERNAL_ORGANIZATION = 'EXTERNAL_ORGANIZATION',
}

export const OrderRecordsOptions: FC<Props> = ({
  title,
  description,
  selectedIndex,
  onSelectIndex,
  onNext,
  hasSkipButton = false,
}) => {
  const location = useLocation();
  const {rowData} = location.state as {rowData: any};
  const isIndividual = useMemo(
    () =>
      rowData?.relying_party_provider_type !==
        ProviderTypes.EXTERNAL_ORGANIZATION &&
      rowData?.relying_party_provider_type !== ProviderTypes.ORGANIZATION &&
      rowData?.client_last_name &&
      rowData?.client_last_name?.length > 0,
    [rowData?.client_last_name, rowData?.relying_party_provider_type]
  );
  const {nameDisplay, organizationDisplay} = chooseProviderDisplayNames(
    rowData.client_first_name ? rowData : rowData.provider_directory_location
  );
  const address = rowData.provider_directory_location.address_1
    ? getOrderRecordsAddress(rowData.provider_directory_location)
    : getOrderRecordsAddressConnection(rowData.provider_directory_location);

  const history = useHistory();
  const handleGoBack = useCallback(async () => {
    history.goBack();
  }, [history]);
  const handleSkip = useCallback(async () => {
    history.replace(
      `/portal-patients/connections/${rowData.consumer_uuid}/order-records`
    );
  }, [history, rowData.consumer_uuid]);

  return (
    <Container>
      <MainHeader title={title} description={description} />
      <TextGroup isSmall={hasSkipButton}>
        {nameDisplay && (
          <TitleText>
            {nameDisplay}
            {!isIndividual && ' & Associates'}
          </TitleText>
        )}
        {organizationDisplay && (
          <DescriptionText>{organizationDisplay}</DescriptionText>
        )}
        {address && <DescriptionText>{address}</DescriptionText>}
      </TextGroup>
      <SubHeaderContainer>
        <SubHeader>Select Type of Records</SubHeader>
      </SubHeaderContainer>
      <OrderRecordItem
        iconSrc={BlueCheckCircle}
        iconSelectedSrc={SuccessBlueIcon}
        title="Medical Records"
        description="Medical Records are your reports, conditions, prescriptions, lab results medical history, etc."
        selected={selectedIndex === 'TEXT'}
        onClick={() => onSelectIndex('TEXT')}
        isSmall={hasSkipButton}
      />
      <OrderRecordItem
        iconSrc={BlueCheckCircle}
        iconSelectedSrc={SuccessBlueIcon}
        title="Images"
        description="Images are your ultrasounds, x-rays, MRIs, and other types of imaging."
        selected={selectedIndex === 'IMAGE'}
        onClick={() => onSelectIndex('IMAGE')}
        isSmall={hasSkipButton}
      />
      <OrderRecordItem
        iconSrc={BlueCheckCircle}
        iconSelectedSrc={SuccessBlueIcon}
        title="Both Medical Records & Images"
        description="Request both Medical Records and Images."
        selected={selectedIndex === 'BOTH'}
        onClick={() => onSelectIndex('BOTH')}
        isSmall={hasSkipButton}
      />
      <ButtonWrapper>
        <CancelButton onClick={handleGoBack} />
        {hasSkipButton && (
          <Button variant="outlined" onClick={handleSkip}>
            Skip
          </Button>
        )}
        <Button onClick={onNext}>Next</Button>
      </ButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 16px;
`;

const SubHeaderContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const SubHeader = styled.span`
  font-size: 14px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const TextGroup = styled.div<{
  isSmall: boolean;
}>`
  height: ${({isSmall}) => (isSmall ? 45 : 98)} px;
  width: 430px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 16px 16px 16px;
  border: 1px solid ${colors.grey1100};
`;

const TitleText = styled.span`
  font-size: 16px;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  line-height: 24px;
  color: ${colors.black};
`;

const DescriptionText = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
  line-height: 21px;
  color: ${colors.black};
`;

const ButtonWrapper = styled.div``;
