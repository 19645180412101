import React, {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Typography} from '@material-ui/core';
import {Form, Formik} from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import {ConfirmModal} from '../../../../shared/components/confirmModal';
import {
  INPUT_WIDTH_TYPES,
  InputFormControlWithFormik,
} from '../../../../shared/components/form';
import {useErrorHandling} from '../../../../shared/hooks/useErrorHandling';
import {NotFoundForm} from '../../constants/providerDetailsItem';
import {providerNotFound} from '../../utils/fetchers';

interface Props {
  isOrganization?: boolean;
  isOpen: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MissingProviderModal: React.FC<Props> = ({
  isOrganization,
  isOpen,
  setOpenModal,
}) => {
  const {patientUuid} = useParams<{patientUuid: string}>();
  const handleError = useErrorHandling();
  const [openThankYouModal, setOpenThankYouModal] = useState(false);

  const closeModal = useCallback(() => {
    setOpenModal(false);
  }, [setOpenModal]);

  const validationSchema = Yup.object({
    provider_name: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required(
        // eslint-disable-next-line quotes
        `${isOrganization ? 'Organization' : "Provider's"} name is required`
      ),
    provider_location: Yup.string()
      .trim()
      .max(50, 'Must be 50 characters or less')
      .required('Location is required'),
  });

  const handleSubmit = async (values: NotFoundForm) => {
    try {
      await providerNotFound(patientUuid, isOrganization, values);
      setOpenThankYouModal(true);
    } catch (error) {
      if (error instanceof Error) {
        return handleError(error);
      }
    }
  };

  return (
    <Formik
      initialValues={{provider_name: '', provider_location: ''}}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {formik => (
        <ConfirmModal
          isOpen={isOpen}
          confirmBtnText={openThankYouModal ? 'Close' : 'Submit'}
          isShowCancelBtn={!openThankYouModal}
          maxWidth={480}
          minWidth={480}
          onClose={closeModal}
          onConfirm={openThankYouModal ? closeModal : formik.submitForm}
        >
          {openThankYouModal ? (
            <ModalHeader>
              <Title>Thank You</Title>
              We received your information. We will notify you when your request
              has been completed.
            </ModalHeader>
          ) : (
            <>
              <ModalHeader>
                <Title>Can’t find your provider or location?</Title>
                Please send us your provider and their location and we will add
                it to our database.
              </ModalHeader>
              <Form>
                <FieldWrapper>
                  <InputFormControlWithFormik
                    required
                    id="provider_name"
                    name="provider_name"
                    label={
                      isOrganization ? 'Organization Name' : 'Provider Name'
                    }
                    testIdPrefix="provider-name"
                    width={INPUT_WIDTH_TYPES.AUTO}
                    error={
                      (formik.touched.provider_name &&
                        formik.errors.provider_name) ||
                      ''
                    }
                    placeholder={
                      isOrganization
                        ? 'Enter organization name'
                        : 'Enter first and last name'
                    }
                  />
                  <InputFormControlWithFormik
                    required
                    id="provider_location"
                    name="provider_location"
                    label="Provider Location"
                    testIdPrefix="provider-location"
                    width={INPUT_WIDTH_TYPES.AUTO}
                    error={
                      (formik.touched.provider_location &&
                        formik.errors.provider_location) ||
                      ''
                    }
                    fullWidth
                    multiline
                    placeholder="Enter provider’s address"
                  />
                </FieldWrapper>
              </Form>
            </>
          )}
        </ConfirmModal>
      )}
    </Formik>
  );
};

const Title = styled(Typography)`
  margin: 0;
  font-size: 18px;
  font-weight: bold;
`;

const ModalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  margin-bottom: 24px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
