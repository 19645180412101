import {envConfig} from '../../../../env';
import {ROLES} from '../../../shared/constants/roles';
import {
  isUserIssuerAdminOrOwner,
  isUserRelyingPartyAdminOrOwner,
} from '../../../shared/utils/user';

export const getPermissionRoles = roles => {
  const isAssistedOperatingModeEnabled =
    envConfig.REACT_APP_VIEW_ORDERS_TAB_ENABLED ||
    envConfig.REACT_APP_ORDER_RECORDS_TAB_ENABLED;

  if (isUserIssuerAdminOrOwner(roles)) {
    return [ROLES.ISSUER_ADMIN, ROLES.ISSUER_SUPPORT_AGENT];
  }
  if (isUserRelyingPartyAdminOrOwner(roles)) {
    const availableRoles = [
      ROLES.RELYING_PARTY_ACTIVITY_LOG_VIEWER,
      ROLES.RELYING_PARTY_RECORDS_REQUESTER,
      ROLES.RELYING_PARTY_RECORDS_VIEWER,
    ];

    return isAssistedOperatingModeEnabled
      ? [
          ...availableRoles,
          ROLES.RELYING_PARTY_PATIENT_ASSISTANT,
          ROLES.RELYING_PARTY_ADMIN,
        ]
      : [...availableRoles, ROLES.RELYING_PARTY_ADMIN];
  }
  return null;
};
